import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import English from './strings/en.json';
import Spanish from './strings/es.json';
import Dutch from './strings/de.json';
import FrenchCanadian from './strings/fr-CA.json';
import French from './strings/fr-Fr.json';
import Italian from './strings/it.json';
import DutchNl from './strings/nl.json';
import Polish from './strings/pl.json';
import Portuguese from './strings/pt-PT.json';
import Swedish from './strings/sv.json';
import Turkish from './strings/tr.json';

export const Context = React.createContext({});

//uses browser to set locale; Not really needed unless we want to set it up as a fallback.
const lc = navigator.language;
const localeMessagesMap: any = {
  'en-US': English,
  en: English,
  es: Spanish,
  'fr-CA': FrenchCanadian,
  'fr-FR': French,
  de: Dutch,
  it: Italian,
  nl: DutchNl,
  pl: Polish,
  pt: Portuguese,
  sv: Swedish,
  tr: Turkish,
};

let lang: any;
lang = localeMessagesMap[lc];

const Wrapper = (props: any) => {
  const [locale, setLocale] = useState(lc);

  const [messages, setMessages] = useState(lang);

  function selectLanguage(value: string) {
    const newLocale = value;
    setLocale(newLocale);

    // If the locale is found in the map, set the corresponding messages,
    // otherwise fall back to English.
    const selectedMessages = localeMessagesMap[newLocale] || English;
    setMessages(selectedMessages);
  }

  return (
    <Context.Provider value={{locale, selectLanguage}}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
